import React, {createContext, useContext, useState} from 'react';

const emptyQuotation = {
    billing: {
        firstname: '',
        lastname: '',
        telephone: '',
        company: '',
        street: '',
        city: '',
        region: '',
        postcode: '',
        country_code: ''
    },
    shipping: {
        firstname: '',
        lastname: '',
        telephone: '',
        company: '',
        street: '',
        city: '',
        region: '',
        postcode: '',
        country_code: ''
    },
    same_as_shipping: true,
    shipping_address_selection: '',
    apply_taxes: true, 
    notifications: [],
    email: null,
    quotation_id: null,
    dp_code: null,
    schedule: {},
    custom_schedule: false,
};

const QuotationContext = createContext(emptyQuotation);

export function QuotationContextProvider(props) {

    const [quotation, setQuotation] = useState(emptyQuotation);

    return (
        <QuotationContext.Provider value={[quotation, setQuotation]}>
            {props.children}
        </QuotationContext.Provider>
    );
}

export const useQuotationContext = () => useContext(QuotationContext);
