import React, { useState } from 'react';
import Quantity from '../../../../ProductQuantity'; 
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import NotificationList from './notificationList';
import UiButton from '../../../../Ui/UiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import { useCartContext } from '../../../../../contexts/CartContext';
import styles from '../style.module.css';
import { gql, useMutation } from '@apollo/client';
import { useCartScheduler } from '../../../../../hooks/useShippingScheduler';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { buildSchedule, getScheduleSaveData } from '../../../../ShippingScheduler/helpers';
import { pluralise } from '../../../../Checkout/Sections/helpers';
import ShippingScheduler from '../../../../ShippingScheduler/shippingScheduler';

export default function ShipmentSection({step, activeSection}) {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart, resetCart} = useCartContext();
    const ActiveSection = ({children}) => step===activeSection ? children : null;
    const [placeQuotationMutation] = getPlaceQuotationMutation();
    const [status, setStatus] = useState('');

    function submit(e) {
        e.preventDefault();

        const notificationsArray = [...quotation.notifications];
        if (e.target.elements.new_recipient.value.length) {
            notificationsArray.push(e.target.elements.new_recipient.value);
        }

        setQuotation({...quotation, loading: true});
        const notifyList = notificationsArray.join(',');

        placeQuotationMutation({variables: {cartId: cart.id, applyTaxes: quotation.apply_taxes, notifyList: notifyList, shippingScheduleRows: getScheduleSaveData(quotation.schedule)}})
            .then(data => {
                const {quotation_id, dp_code} = data.data.placeQuotation;
                resetCart();
                setQuotation({...quotation, quotation_id: quotation_id, dp_code: dp_code, loading: false});
            })
            .catch(error => {
                setStatus(error.message);
                setQuotation({...quotation, loading: false});
            });
    }

    function SubmitStatus() {
        return (status ? <p className={styles.status}>{status}</p> : null);
    }

    function ButtonContent() {
        if (quotation.loading) return <FontAwesomeIcon icon={faSpinner} spin />;
    
        return (
            <span>
                Continue &nbsp;&nbsp; <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </span>
        );
    }

    return (
        <div className={styles.section}>
            <h2>
                <div>
                    <span className={styles.stepNumber}>{step}</span>
                    <span>Shipments & notification</span>
                </div>
            </h2>
            <ActiveSection>
                <form className={styles.sectionInner} onSubmit={e => submit(e)}>
                    <Delivery />
                    <Tax />
                    <Notification />
                    <div className={styles.createCta}>
                        <UiButton type="submit" className="button button--secondary" disabled={quotation.loading}>
                            <ButtonContent />
                        </UiButton>
                    </div>
                    <SubmitStatus />
                </form>
            </ActiveSection>
        </div>
    );
}

function Delivery() {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart} = useCartContext();
    const skipQuery = !!Object.keys(quotation.schedule).length;
    const {loading, error, data} = useCartScheduler(cart.id, skipQuery);
    const [showScheduler, setShowScheduler] = useState(quotation.custom_schedule);

    function DynamicContent() {
        let schedule;

        if (skipQuery) {    // customised schedule
            schedule = quotation.schedule;
        } else {
            if (data) {     // original schedule
                schedule = buildSchedule(data.shippingScheduleSetup);
                setQuotation({...quotation, schedule});
            } else {        // no schedule - waiting for data
                return (
                    <center>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </center>
                );
            }
        } 

        const suggestedShipments = schedule?.shipments?.length ?? 1;
        const setSchedule = s => setQuotation({...quotation, schedule: s, custom_schedule: true});

        return (
            <div>
                <p>We will base your quote on {pluralise(suggestedShipments, 'shipment')}. 
                Or, <a onClick={() => setShowScheduler(!showScheduler)}>create a custom schedule</a> here.</p>
                {showScheduler && <ShippingScheduler schedule={schedule} setSchedule={setSchedule} currency={cart.subtotal.currency} />}
            </div>
        );
    }

    return (
        <div>
            <h3>Delivery</h3>
            <DynamicContent />
        </div>
    );
}

function Tax() {
    const [quotation, setQuotation] = useQuotationContext();

    return (
        <div>
            <h3 className={styles.divider}>Tax</h3>
            <p>We will use your address and product selection to apply taxes to your quote.</p>
            <p>
                <NanoCheckbox 
                    label="Do not apply taxes" 
                    checked={!quotation.apply_taxes}
                    onNanoChange={val => setQuotation({...quotation, apply_taxes: !quotation.apply_taxes})} />
            </p>
            <div className="info-banner info-banner--advisory">
                <div className="info-banner__content">
                    <p className="info-banner__text">The VAT/TAX rate applied is based on the country from which the goods are dispatched. We ship from warehouses based in the Netherlands, the United Kingdom and Memphis, USA. In most cases UK shipments are shipped from the UK charging VAT at 20% and EU shipments from the Netherlands at 21%, where applicable. State taxes will apply for US customers. Please ensure you provide accurate tax and billing information when your account is created in order to prevent incorrect billing.</p>
                </div>
            </div>
        </div>
    );
}

function Notification() {
    return (
        <div>
            <h3 className={styles.divider}>Notification</h3>
            <p>We will send this quote to the following recipients:</p>
            <NotificationList />
        </div>
    );
}

function getPlaceQuotationMutation() {
    return useMutation(gql`
        mutation PlaceQuotation($cartId: String!, $applyTaxes: Boolean!, $notifyList: String!, $shippingScheduleRows: [ScheduleInputRow!]!) {
            placeQuotation(        
                input: {
                    cart_id: $cartId
                    apply_taxes: $applyTaxes
                    notify_list: $notifyList
                    shipping_schedule_rows: $shippingScheduleRows
                }
            ) {
                quotation_id
                dp_code
                order {
                    order_number
                    grand_total
                    sub_total
                    tax
                    shipping
                }
            }
        }
    `);
}


