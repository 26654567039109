import React, { useState } from 'react';
import { NanoInput, NanoSelect, NanoSelectOption } from '@nanoporetech-digital/components-react';
import CountrySelect from './CountrySelect'
import RegionInput from './RegionInput'
import useCountries from '../../hooks/useCountries';

export default function BillingForm({formData, setFormData}) {
    const countries = useCountries();

    function updateFormData(e) {
        if (e.target.name==='country_code') {
            setFormData({...formData, country_code: e.target.value, region: ''});
        } else {
            setFormData({...formData, [e.target.name]: e.target.value});
        }
    }

    return(
        <>
            <h3>Enter your billing address</h3>
            <CountrySelect countries={countries} formData={formData} updateFormData={updateFormData} />
            <NanoInput maxlength="25" label="First name*" required="true" show-inline-error="submitThenDirty" name="firstname" value={formData.firstname} onNanoChange={e => updateFormData(e)} />
            <NanoInput maxlength="25" label="Last name*" required="true" show-inline-error="submitThenDirty" name="lastname" value={formData.lastname} onNanoChange={e => updateFormData(e)} />
            <NanoInput maxlength="40" label="Telephone*" required="true" show-inline-error="submitThenDirty" name="telephone" value={formData.telephone} onNanoChange={e => updateFormData(e)} />
            <NanoInput maxlength="40" label="Organisation*" required="true" show-inline-error="submitThenDirty" name="company" value={formData.company} onNanoChange={e => updateFormData(e)} />
            <NanoInput maxlength="80" label="Address*" required="true" show-inline-error="submitThenDirty" name="street" value={formData.street} onNanoChange={e => updateFormData(e)} />
            <NanoInput maxlength="40" label="City*" required="true" show-inline-error="submitThenDirty" name="city" value={formData.city} onNanoChange={e => updateFormData(e)} />
            <RegionInput countries={countries} formData={formData} updateFormData={updateFormData} />
            <NanoInput maxlength="40" label="Zip/Postal code*" required="true" show-inline-error="submitThenDirty" name="postcode" value={formData.postcode} onNanoChange={e => updateFormData(e)} />
        </>
    );
}
