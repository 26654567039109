import React from 'react';
import useCountries, { getCountryName } from '../../../hooks/useCountries';

export default function Billing({address}) {
    const countries = useCountries();
    if (!address?.id) return null;

    return (
        <div>
            <h3>Billing address</h3>
            <ul>
                <li>{address.firstname} {address.lastname}</li>
                <li>{address.company}</li>
                <li>{address.street.join(<br />)}</li>
                <li>{address.city}</li>
                <li>{address.region.region}</li>
                <li>{address.postcode}</li>
                <li>{getCountryName(countries,address.country_id)}</li>
            </ul>
        </div>
    );
}
