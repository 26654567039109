import React, { useState } from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import BillingForm from '../../../../Form/BillingForm';
import ShippingForm from '../../../../Form/ShippingForm';
import Navigate from '../navigate';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useSetGuestBillingAddress, useSetGuestShippingAddress, useSetShippingMethod, updateContextCart } from '../../../../../hooks/useCart';
import styles from '../style.module.css';

export default function Guest({setMode, setActiveSection, status, setStatus}) {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart, setCart} = useCartContext();
    const [setBillingMutation] = useSetGuestBillingAddress(cart.id);
    const [setShippingMutation] = useSetGuestShippingAddress(cart.id);
    const [setShippingMethodMutation] = useSetShippingMethod();
    const [billing, setBilling] = useState({...quotation.billing});
    const [shipping, setShipping] = useState({...quotation.shipping});

    async function submit(e) {
        e.preventDefault();

        const updatedQuotation = {...quotation, billing: billing, shipping: shipping, loading: true};
        setQuotation(updatedQuotation);

        try {
            await setBillingMutation({variables: {cartId: cart.id, address: billing, sameAsShipping: updatedQuotation.same_as_shipping}});
            if (!updatedQuotation.same_as_shipping) {
                await setShippingMutation({variables: {cartId: cart.id, address: shipping}});
            }
            const responseData = await setShippingMethodMutation({ variables: { cartId: cart.id } });
            updateContextCart(cart, setCart, responseData);
            setStatus('');
            setQuotation({...updatedQuotation, loading: false});
            setActiveSection(2);
        } catch(err) {
            setStatus("Error saving addresses");
        }
    }

    function SubmitStatus() {
        return (status ? <p className={styles.status}>{status}</p> : null);
    }

    return (
        <form className={styles.sectionInner} onSubmit={e => submit(e)}>
            <Navigate setMode={setMode} />
            <BillingForm formData={billing} setFormData={setBilling} />
            <h3>Your order will be delivered to the same address</h3>
            <p>
                <NanoCheckbox 
                    label="Deliver to a different address" 
                    checked={!quotation.same_as_shipping}
                    onNanoChange={() => setQuotation({...quotation, billing: billing, shipping: shipping, same_as_shipping: !quotation.same_as_shipping})} />
            </p>
            {!quotation.same_as_shipping && <ShippingForm formData={shipping} setFormData={setShipping} />}
            <Navigate setMode={setMode} disable={quotation.loading} />
            <SubmitStatus />
        </form>
    );
}
