import React, { useState } from 'react';
import BillingAddress from '../../../../Checkout/Address/billing';
import ShippingAddress from '../../../../Checkout/Address/shipping';
import ShippingSelect from '../../../../Checkout/Address/shippingSelect';
import useAddresses from '../../../../../hooks/useAddresses';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import ShippingForm from '../../../../Form/ShippingForm';
import Navigate from '../navigate';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useSetCustomerBillingAddress, useSetCustomerNewShippingAddress, useSetCustomerExistingShippingAddress, useSetShippingMethod, updateContextCart } from '../../../../../hooks/useCart';
import styles from '../style.module.css';

export default function Customer({setMode, setActiveSection, status, setStatus}) {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart, setCart} = useCartContext();
    const [setBillingMutation] = useSetCustomerBillingAddress(cart.id);
    const [setNewShippingMutation] = useSetCustomerNewShippingAddress(cart.id);
    const [setExistingShippingMutation] = useSetCustomerExistingShippingAddress(cart.id);
    const [setShippingMethodMutation] = useSetShippingMethod();
    const { billing, shipping } = useAddresses();
    const [newShipping, setNewShipping] = useState(quotation.shipping);
    const createNewShipping = quotation.shipping_address_selection==='_new';

    const changeSelection = (val) => setQuotation({...quotation, shipping_address_selection: val});
    
    function getShippingAddressIdFromCurrentSelection() {
        switch(quotation.shipping_address_selection) {
            case '': return shipping[0].id;
            case '_new': return null;
        }
        return parseInt(quotation.shipping_address_selection);
    }
    
    async function submit(e) {
        e.preventDefault();

        const saId = getShippingAddressIdFromCurrentSelection();
        const baId = parseInt(billing.id);
        const sameAsShipping = (baId && baId===saId);
        const updatedQuotation = {...quotation, shipping: newShipping, shipping_address_id: saId, same_as_shipping: sameAsShipping, loading: true};

        setQuotation(updatedQuotation);

        try {
            await setBillingMutation({variables: {cartId: cart.id, sameAsShipping: sameAsShipping, customerAddressId: baId}});
            if (!sameAsShipping) {
                if (saId) {
                    await setExistingShippingMutation({variables: {cartId: cart.id, customerAddressId: saId}})
                } else {
                    await setNewShippingMutation({variables: {cartId: cart.id, address: {...newShipping, save_in_address_book: true}}});
                }
            }
            const responseData = await setShippingMethodMutation({ variables: { cartId: cart.id } })
            updateContextCart(cart, setCart, responseData);
            setStatus('');
            setQuotation({...updatedQuotation, loading: false});
            setActiveSection(2);
        } catch(err) {
            setStatus("Error saving addresses");
        }
    }

    function SubmitStatus() {
        return (status ? <p className={styles.status}>{status}</p> : null);
    }

    return (
        <form className={styles.sectionInner} onSubmit={e => submit(e)}>
            <div className={styles.customerAddresses}>
                <BillingAddress address={billing} />
                <ShippingAddress addresses={shipping} shippingAddressId={quotation.shipping_address_selection} />
            </div>
            <ShippingSelect addresses={shipping} selection={quotation.shipping_address_selection} changeSelection={changeSelection} />
            {createNewShipping && <ShippingForm formData={newShipping} setFormData={updatedShipping => setNewShipping(updatedShipping)} />}
            <Navigate setMode={setMode} allowBack={false} disable={quotation.loading} />
            <SubmitStatus />
        </form>
    );
} 
