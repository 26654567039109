import React, { useState } from 'react';
import { useCartContext } from '../../../contexts/CartContext';
import { useCart } from '../../../hooks/useCart';
import CartSummary from '../../Cart/cartSummary';
import AddressSection from './Section/Address';
import ShipmentSection from './Section/Shipment';
import { QuotationContextProvider, useQuotationContext } from '../../../contexts/QuotationContext';
import Success from './success';
import styles from './style.module.css';

export default function QuotationCreate() {
    return (
        <QuotationContextProvider>
            <CreateOrSuccess />
        </QuotationContextProvider>
    );
}

function CreateOrSuccess() {
    const [quotation] = useQuotationContext();

    return (quotation.quotation_id && quotation.dp_code)
        ? <Success />
        : <Create />
}

function Create() {
    return (
        <div className={styles.quoteCreate}>
            <h1>Create a quote</h1>
            <Quotation />
        </div>
    );
}

function Quotation() {
    const { cart, setCart, resetCart } = useCartContext(); 
    const { loading, error, data } = useCart(cart.id);
    const [activeSection, setActiveSection] = useState(1);
    const [quotation, setQuotation] = useQuotationContext();

    if (loading) {
        return <p>Loading ...</p>;
    }

    if (error) {
        if (cart.id?.length) resetCart();
        return <p>Your basket is empty.</p>;
    }

    if (!data?.cart?.items?.length) {
        return <p>Your basket is empty.</p>;
    }

    // init
    (function() {
        if (data.cart.email && !quotation.email) {
            setQuotation({...quotation, email: data.cart.email});
        }
    })();

    return(
        <div className={styles.content}>
            <div className={styles.sections}>
                <AddressSection step={1} activeSection={activeSection} setActiveSection={setActiveSection} />
                <ShipmentSection step={2} activeSection={activeSection} setActiveSection={setActiveSection} />
            </div>
            <CartSummary cartData={data.cart} mode="quote" hideTaxes={!quotation.apply_taxes} shippingSchedule={quotation.schedule} />
        </div>
    );
}
