import React, { useState } from 'react';
import { NanoInput } from '@nanoporetech-digital/components-react';
import UiButton from '../../../../Ui/UiButton';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import styles from '../style.module.css';
import { useCustomerContext } from '../../../../../contexts/CustomerContext';

export default function NotificationList() {
    const [emailField, setEmailField] = useState('');
    const [quotation, setQuotation] = useQuotationContext();

    function addRecipient() {
        if (emailField) {
            const recipients = [...quotation.notifications, emailField];
            setQuotation({...quotation, notifications: recipients});
        }
    }

    function removeRecipient(i) {
        const recipients = [...quotation.notifications];
        recipients.splice(i,1);
        setQuotation({...quotation, notifications: recipients});
    }

    return (
        <div>
            <div className={styles.notifications}>
                <ul>
                    <LoggedInRecipient email={quotation.email}/>
                    <Recipients emails={quotation.notifications} removeRecipient={removeRecipient} />
                </ul>
                <NanoInput 
                    name="new_recipient"
                    type="email"
                    clear-input="true"
                    hide-label="true"
                    placeholder="Enter email address..."
                    required="false"
                    show-inline-error="dirty"
                    value={emailField}
                    onNanoChange={e => setEmailField(e.target.value)}
                    style={{"width":"23.5rem"}}
                />
            </div>
            <div className={styles.addRecipient}>
                <UiButton className="button button--secondary button--keyline" onClick={() => addRecipient()}>
                    Add another recipient
                </UiButton>
                <span>Note: any recipient can complete the purchase</span>
            </div>
        </div>
    );
}

function Recipients({emails, removeRecipient}) {    
    return emails.map((email,i) => 
        <li key={i}>
            <span className={styles.recipient}>{email}</span>
            &nbsp;&nbsp;<a onClick={()=>removeRecipient(i)}>Remove</a>
        </li>
    );
}

function LoggedInRecipient({email}) {    
    if (email) {
        return <li><span className={styles.recipient}>{email}</span></li>
    }

    return null;
}

export function ConfirmedRecipients({loggedInEmail, notifications}) {
    return (
        <ul>
            <LoggedInRecipient email={loggedInEmail}/>
            {notifications.map((email,i) => <li key={i}><span className={styles.recipient}>{email}</span></li>)}
        </ul>
    );
}
