import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import {Price} from "../../magento/peregrine";
import {isNonTransactional} from "../../hooks/siteData";

export default function Quantity({
    mode = "default",
    unitPrice = 0,
    currencyCode = "",
    value = 0,
    maxValue,
    onValueChange = () => {},
}) {
    if (isNonTransactional()) {
        return null;
    }

    const [focused, setFocus] = useState(false);
    if (mode === "years") {
        const items = []

        for (var i = 1; i <= maxValue; i++) {
            items.push(
                <span key={i.toString()}>
                    <input id={"qty_increment_" + i} type="radio" name="qty" value={i} />
                    <label className="button button--light" htmlFor={"qty_increment_" + i}>{i} {i === 1 ? 'year' : 'years'}&nbsp; <small><Price currencyCode={currencyCode} value={unitPrice * i}/></small></label>
                    <style jsx>{`
                        span {
                            margin-right: 1rem;
                        }
                        input {
                            display: none;
                        }
                        input[type="radio"]:checked+label, input[type="radio"]:checked+label:hover {
                            background-color: #0084a9;
                            color: #fff;
                            cursor: pointer;
                        }
                    `}</style>
                </span>
            )
        }

        return (
            <>
                <div className="container">
                    <div className="product-view-qty-buttons">
                        {items}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="container">
                <div
                    className="button button--qty minus btn"
                    onClick={() => {
                        if (value > 0) {
                            onValueChange(value - 1);
                        }
                    }}>
                    <FontAwesomeIcon icon={faMinus} />
                </div>
                {/*<nano-input placeholder="0" type="text"></nano-input>*/}
                <div className={clsx('sc-nano-input-h sc-nano-input-s has-label hydrated', focused && 'has-focus')} >
                    <div className="input sc-nano-input">
                        <div className="native-input-wrap sc-nano-input">
                            <input
                                className="native-input sc-nano-input quantityInput"
                                aria-labelledby="nano-input-1-lbl"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                spellCheck="false"
                                value={value}
                                onChange={event => {
                                    if (event.target.value > maxValue) {
                                        onValueChange(maxValue);
                                    } else {
                                        onValueChange(event.target.value);
                                    }
                                }}
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                                type="text" />
                        </div>
                    </div>
                </div>
                <div
                    disabled={maxValue !== undefined ? value === maxValue : false}
                    className="button button--qty plus btn"
                    onClick={() => {
                        if (maxValue !== undefined) {
                            if ( value < maxValue) {
                                onValueChange(value + 1)
                            }
                        } else {
                            onValueChange(value + 1);
                        }
                    }}>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </div>
            <style jsx>{`
            .container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                text-align: center;
                width: 7rem;
            }
            .btn {
                background-color: #0084a9;
                box-shadow: none;
                border-radius: 0;
                color: #FFF;
                font-size: 0.875rem;
                margin: 0px;
                padding: .4rem .6rem;
                box-shadow: none;
            }
            .btn:hover {
                box-shadow: none;
            }
            .btn:focus {
                border-radius: 0px;
            }
            .sc-nano-input {
                font-size: 1rem;
            }
            .sc-nano-input-h {
                --placeholder-color: initial;
                --placeholder-font-style: initial;
                --placeholder-font-weight: initial;
                --placeholder-opacity: .5;
                --padding-top: 0;
                --padding-end: var(--nano-input-padding-end, var(--nano-input-padding, 8px));
                --padding-bottom: var(--nano-input-padding-bottom, var(--nano-input-padding, 8px));
                --padding-start: var(--nano-input-padding-start, var(--nano-input-padding, 8px));
                --color: "currentColor";
                --color--invalid: var(--nano-color-danger-rgb, 239, 65, 53);
                --color--focus: var(--nano-color-base, var(--nano-color-primary, #0084a9));
                --color--focus-rgb: var(--nano-color-base-rgb, var(--nano-color-primary-rgb, 0, 132, 169));
                --color--focus-shade: var(--nano-color-shade, var(--nano-color-primary-shade, #007090));
                --color--focus-tint: var(--nano-color-tint, var(--nano-color-primary-tint, #2696b6));
                --color--focus-contrast: var(--nano-color-contrast, var(--nano-color-primary-contrast, #fff));
                --input-font-size: .87em;
                --input-text-color: var(--nano-input-text-color, #4a4a4a);
                --input-border-width: var(--nano-input-border-width, 2px);
                --input-border-color: var(--nano-input-border-color, #e4e6e8);
                --input-border-radius: var(--nano-input-border-radius, 0);
                --input-border-width-sh: var(--input-border-width) var(--input-border-width) var(--input-border-width) var(--input-border-width);
                --input-border-style: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color, #e4e6e8);
                --input-border-style--focus: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--focus, #90c6e7);
                --input-border-style--invalid: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--invalid, #fac6c3);
                --input-border-style--invalid-focus: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--invalid, #f36d64);
                --input-bg-color: var(--nano-input-background-color, #fff);
                --input-bg-color--focus: var(--input-bg-color);
                --input-bg-color--invalid: var(--nano-input-background-color, #fee8de);
                --invalid-msg-color: rgba(var(--color--invalid), 1);
                --invalid-msg-font-size: .75em;
                --clear-btn-color: rgba(0, 0, 0, .3);
                --clear-btn-color--hover: rgba(var(--color--focus-rgb), 1);
                --clear-btn-color--invalid: rgba(var(--color--invalid), 1);
                --label-color: var(--nano-input-label-color, "currentColor");
                --label-color--focus: var(--label-color);
                --label-color--invalid: "currentColor";
                --label-font-size: initial;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                width: 100%;
                padding: 0 !important;
                color: var(--color);
            }
            .input.sc-nano-input {
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                text-decoration: inherit;
                text-overflow: inherit;
                text-transform: inherit;
                text-align: inherit;
                white-space: inherit;
                color: inherit;
                border-radius: var(--input-border-radius);
                width: 100%;
                padding: 0 !important;
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1 0 auto;
                flex: 1 0 auto;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                background: var(--input-bg-color);
                border: var(--input-border-style);
                border-width: var(--input-border-width-sh);
                font-size: var(--input-font-size);
                border: 1px solid #0084a9;
            }
            .native-input-wrap.sc-nano-input {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
            }
            .native-input.sc-nano-input {
                padding-left: unset;
                padding-right: unset;
                -webkit-padding-start: var(--padding-start);
                padding-inline-start: var(--padding-start);
                -webkit-padding-end: var(--padding-end);
                padding-inline-end: var(--padding-end);
            }
            .native-input.sc-nano-input {
                border-radius: var(--input-border-radius);
                padding-left: var(--padding-start);
                padding-right: var(--padding-end);
                padding-top: var(--padding-top);
                padding-bottom: var(--padding-top);
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                text-decoration: inherit;
                text-overflow: inherit;
                text-transform: inherit;
                text-align: inherit;
                white-space: inherit;
                color: inherit;
                color: var(--input-text-color);
                display: inline-block;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                min-width: 0;
                border: 0;
                outline: none;
                background: transparent;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                line-height: 2em;
                min-height: 2em;
                margin: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 44px;
            }
            `}</style>
        </>
    );
};

